import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck } from '@fortawesome/free-solid-svg-icons'

function UnstyledListComponent(props) {
  return (
    <div>
            <ul className="list-unstyled ps-0">
                {
                    props.achievementArray.map((achievement,index)=>(
                        <li key={index}>
                            <svg className="bi homeIcon" width="16" height="16"><FontAwesomeIcon icon={faCheck} /></svg>
                            {achievement}
                        </li>
                    ))
                }
            </ul>
    </div>
  )
}

export default UnstyledListComponent