import React from 'react';
import '../css/events.css'; // Import your CSS file

const Events = () => {
  return (
    <div>
      <section className="full-width-image-section">
        <img src="/images/group-photo-2.jpg" alt='event_image1' className="img-fluid"></img>
      </section>

      <div className="album py-5 bg-body-tertiary">
          <div className="container">

            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
              <div className="col">
                <div className="card card h-100 shadow-sm d-flex flex-column">
                  <img src="/images/uniform_rupandehi.jpg" alt="event_image2" style={{ objectFit: 'cover', height: '200px' }} ></img>
                  <div className="card-body">
                    <p>स्कोलर्स होम एफ ए रुपन्देहीलाई ( SCHOLARS HOME  FA RUPANDEHI) ५५ थान जर्सी प्रदान । </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card card h-100 shadow-sm d-flex flex-column">
                <img src="/images/pramanpatra.jpg" alt="event_image3" style={{ objectFit: 'cover', height: '200px' }} ></img>
                  <div className="card-body">
                    <p>मणिग्राममा रहेको आत्मशान्ति अनाथ आश्रमको अक्षयकोषको लागि सहयोग गरेबापत रुपन्देही सामाजीक सञ्जाल अष्ट्रेलियालाई प्रदान गरिएको सम्मान पत्र ।
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card card h-100 shadow-sm d-flex flex-column">
                  <img src="/images/lumbini_institute.jpg" alt="event_image4" style={{ objectFit: 'cover', height: '200px' }} ></img>
                  <div className="card-body">
                    <p>लुम्बिनी ईन्टिच्युट अफ टेक्निकल साईन्स मा अ.न.मी पढ्नको निम्ती आस्था पराजुलिलाई रु ६९२७५ प्रदान गरियो।</p>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="card card h-100 shadow-sm d-flex flex-column">
                  <img src="/images/help_kid_pic.jpg" alt="event_image5" style={{ objectFit: 'cover', height: '200px' }} ></img>
                  <div className="card-body">
                    <p>रुपन्देही सामाजीक सञ्जाल अष्ट्रेलियाले बुटवल स्थित बुटवल मा. वी. मा पढ्ने विद्यार्थि लाल बहादुर गाहालाई एक वर्ष सम्म पढाईदिनको निम्ती दिईएको खर्च । नेपाली रुपैयां दसहजार एकसय पचास ।</p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card card h-100 shadow-sm d-flex flex-column">
                  <img src="/images/school_with_kid.jpg" alt="event_image6" style={{ objectFit: 'cover', height: '200px' }} ></img>
                  <div className="card-body">
                    <p>रुपन्देही सामाजीक सञ्जाल अष्ट्रेलियाले बुटवल स्थित कान्ती कुञ्जमा पढ्ने विद्यार्थि सागर क्षेत्रीलाई एक वर्ष सम्म पढाईदिनको निम्ती दिईएको खर्च । नेपाली रुपैयां एघार हजार पांचसय पचहत्तर ।</p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card card h-100 shadow-sm d-flex flex-column">
                    <img src="/images/bus_with_scene.jpg" alt="event_image7" style={{ objectFit: 'cover', height: '200px' }} ></img>
                  <div className="card-body">
                    <p>पश्चिम क्षेत्रिय ट्राफिक प्रहरि कार्यलय बुटवलको सहयोगमा रुपन्देही सामाजीक सञ्जाल अष्ट्रेलियाले ट्राफिक नियम सँग सम्बन्धित राखेको एक लाख बराबरको तिनवटा ठूला होडिङ्ग बोर्ड ।</p>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="card card h-100 shadow-sm d-flex flex-column">
                    <img src="/images/help_rupandehi.jpg" alt="event_image8" style={{ objectFit: 'cover', height: '200px' }} ></img>
                  <div className="card-body">
                    <p>लुम्बिनी अञ्चल अस्पतालको ईमरजेन्सि र प्रसुति वाडमा ईलेक्ट्रोनिक पानी ट्याङ्की राख्नको लागि नेपाली नगद एक लाख विस हजार रुपैयां सहयोग</p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card card h-100 shadow-sm d-flex flex-column">
                    <img src="/images/anath_asaram.jpg" alt="event_image9" style={{ objectFit: 'cover', height: '200px' }} ></img>
                  <div className="card-body">
                    <p>रुपन्देहीको मणिग्राममा रहेको आत्मशान्ति अनाथ आश्रमको अक्षयकोषको लागि आश्रमकी अध्यक्ष मैनादेवी भण्डारीलाई  नेपाली एक लाख रुपैंयाको चेक हस्तान्त्रण</p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card card h-100 shadow-sm d-flex flex-column">
                    <img src="/images/sangeeti_samaj.jpg" alt="event_image10" style={{ objectFit: 'cover', height: '200px' }} ></img>
                  <div className="card-body">
                     <p>“नयाँ वर्ष साँगितिक साँझ २०७३” बाट संकलित रकम ७ मे २०१६ का दिन रुपन्देहीका विभिन्न परोपकारी संघ संस्थाहरुलाई हस्तान्त्रण गरियो।</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Events;
