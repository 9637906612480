// src/App.js
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './pages/Header';
import Home from './pages/Home';
import About from './pages/About';
import Team from './pages/team';
import Events from './pages/Events';
import Footer from './pages/Footer';
function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/about" element={<About/>} />
        <Route exact path="/team" element={<Team/>} />
        <Route exact path="/events" element={<Events/>} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
