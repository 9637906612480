import React from 'react'
import { Link } from 'react-router-dom'

function LogoComponent({url}) {
  return (
    <div>
        <Link to="/">
            <img src={url} alt="RSNA Logo" />
        </Link>
    </div>
  )
}

export default LogoComponent