import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Header.css'; // Import your CSS styles for the header
import LogoComponent from  '../components/LogoComponent';
const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <LogoComponent url="images/rsna-logo.jpeg"/>
      </div>
      <nav className="navbar">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About Us</Link>
          </li>
          <li>
            <Link to="/team">Team</Link>
          </li>
          <li>
            <Link to="/events">Events</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
