import React from 'react';
import '../css/home.css';
import CarouselComponent from '../components/CarouselComponent';
import JumbotronComponent from '../components/JumbotronComponent';
import EventComponent from '../components/EventComponent';

const Home = () => {

  const imageArray = [
    '/images/group-photo.jpg',
    '/images/group-photo-2.jpg',
    "/images/group-photo-3.jpg"
    // Add more image URLs as needed
  ];

  const eventData= [
            {
              description :"रुपन्देही सामाजीक सञ्जाल अष्ट्रेलिया द्वारा आयोजित अप्रिल ९ तारिक २०१६ मा आयोजना गरिएको कार्यक्रम “नयाँ वर्ष साँगितिक साँझ २०७३” सम्पन्न ।सो कार्यक्रममा नेपालबाट आएका ख्याती प्राप्त गायक यम बराल,सोनुप पौडेल र गायीका आकांक्षा बस्यालको सांगीतीक प्रस्तुती।",
              image: "/images/sangeeti_samaj.jpg",
              background:"text-bg-dark",
              text: "bg-body-tertiary"
            },
            {
              description :"रुपन्देहीको बुटवल १३ देविनगरमा अवस्थित नेपाल अपाङ्ग आश्राममा रुपन्देही सामाजीक सञ्जाल अष्ट्रेलियाले ह्ववयील चियर बाटै शौचालय भित्र जान मिल्ने शौचालय बनाउनको निम्ति सो आश्रामलाई नेपाली दुई लाख रुपैयाँ को चेक हस्तान्त्रण।",
              image: "/images/rupandehi_checque.jpg",
              background:"bg-body-tertiary",
              text:"bg-dark"
            },
            {
              description :"लुम्बिनी अञ्चल अस्पतालको ईमरजेन्सि र प्रसुति वाडमा ईलेक्ट्रोनिक पानी ट्याङ्की राख्नको लागि नेपाली नगद एक लाख विस हजार रुपैयां सहयोग",
              image: "/images/help_rupandehi.jpg",
              background:"bg-body-tertiary",
              text:""
            },
            {
              description :"रुपन्देही सामाजीक सञ्जाल अष्ट्रेलियाले बुटवल स्थित कान्ती कुञ्जमा पढ्ने विद्यार्थि सागर क्षेत्रीलाई एक वर्ष सम्म पढाईदिनको निम्ती दिईएको खर्च । नेपाली रुपैयां एघार हजार पांचसय पचहत्तर ।",
              image: "/images/school_help.jpg",
              background:"text-bg-danger",
              text:"bg-body-tertiary"
            }
  ]
  return (
    <div>
      <div className='homeContainer'>
        <CarouselComponent images={imageArray}/>
      </div>

      <JumbotronComponent url="/images/rupandehi_featured_image.jpg" />
    
      <EventComponent eventDataArray={eventData} value={1} />

    </div>
  );
};

export default Home;
