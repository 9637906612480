import React from 'react'
import JBodyComponent from './JBodyComponent'

function JumbotronComponent(props) {
  return (
    <div>
         <div className="container col-xxl-8 px-4 py-5">
            <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                <div className="col-10 col-sm-6 col-lg-6">
                    <img src={props.url} className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy"></img>
                </div>
                <div className="col-lg-6">
                    <JBodyComponent/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default JumbotronComponent