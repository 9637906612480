import React from 'react'
import UnstyledListComponent from './UnstyledListComponent'

function JBodyComponent() {
    const achievementArray=[
        'Donating $2500.00 to build a wheelchair accessible toilet in Rupandehi, Nepal.',
        'Providing warm clothes to poor, orphan, and underprivileged children during winter, amounting to approximately $1000.00.',
        'Installing an electric water filter in a public hospital, an essential step towards improving healthcare, with a cost of $1250.00.',
        'Contributing $1000.00 to the central relief fund during the devastating earthquake in Nepal in 2015.',
        'Supporting a health post in a remote village by providing them with an analyzer machine, an investment of $2600.00 towards better healthcare.',
        'Donating 7 patient monitors to a children and women’s hospital in Butwal, empowering emergency services and costing $7500.00.',
        'Extending our help during Covid restrictions by supporting NRNA and providing financial aid and assistance to Covid-impacted Nepalese students.',
        'Providing financial support to numerous community organizations on a small scale.'
    ]

  return (
    <div>
        <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">Rupandehi Social Network </h1>
        <p><strong>Our Achievements:</strong></p>
        <UnstyledListComponent achievementArray={achievementArray}/>
    </div>
  )
}

export default JBodyComponent