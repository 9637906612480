import React from 'react';
import '../css/Footer.css'; // Import your CSS file
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSquareTwitter } from '@fortawesome/free-brands-svg-icons'
import {faSquareFacebook} from '@fortawesome/free-brands-svg-icons'
import {faSquareInstagram} from '@fortawesome/free-brands-svg-icons'
import Logo from  '../components/LogoComponent';

const Footer = () => {
  return (
    <div>
      <div className="container">
        <footer className="py-3 my-4">
        <div className="nav justify-content-center">
          <div className="logo">
            <Link to="/">
              <Logo url="images/rsna-logo.jpeg"/>
            </Link>
          </div>
          </div>
          <ul className="nav justify-content-center border-bottom pb-3 mb-3">
            <li className="nav-item"><Link to="/about" className="nav-link px-2 text-body-secondary">About</Link></li>
            <li className="nav-item"><Link to="/team" className="nav-link px-2 text-body-secondary">Team</Link></li>
            <li className="nav-item"><Link to="/events" className="nav-link px-2 text-body-secondary">Events</Link></li>
          </ul>
          <ul className="nav justify-content-center pb-3 mb-3">
                <li className="ms-3 "><a className="text-body-secondary" href="https://www.facebook.com/rsnaaustralia?mibextid=LQQJ4d"><FontAwesomeIcon className="fontDesignIcon Facebook"  icon={faSquareFacebook} /></a></li>
                <li className="ms-3"><a className="text-body-secondary" href="https://www.facebook.com/rsnaaustralia?mibextid=LQQJ4d"><FontAwesomeIcon className="fontDesignIcon Instagram" icon={faSquareInstagram} /></a></li>
                <li className="ms-3"><a className="text-body-secondary" href="https://www.facebook.com/rsnaaustralia?mibextid=LQQJ4d"><FontAwesomeIcon className="fontDesignIcon Twitter" icon={faSquareTwitter} /></a></li>
          </ul>
        </footer>
    </div>
    </div>
  );
};

export default Footer;
