import React from 'react'

function EventComponent(props) {

  return (
    <div>
         { props.value===1 ? (
            <div className="d-md-flex flex-md-equal w-100 my-md-3 ps-md-3">
                {
                    props.eventDataArray.map((eventData,index)=>(
                        <div className={`${eventData.background} me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center`} style={{ width: '100%', maxWidth: '500px' }}>
                            <div className="my-3 py-3">
                                <p className="lead">{eventData.description}</p>
                            </div>
                            <div className={`${eventData.text} bg-body-tertiary shadow-sm mx-auto`} style={{ width: '100%', height: '300px', borderRadius: '21px 21px 0 0', overflow: 'hidden' }}>
                                <img src={eventData.image} alt={`eventImage${index}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div>
                        </div>
                    ))
                }
            </div>
         ):'' }
    </div>
  )
}

export default EventComponent