import React from 'react';

const Team = () => {
  return (
    <div>
      <div className="container">
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{ width: '80%', margin: '0 auto', textAlign: 'center' }}>
            <h1 style={{ height: 'auto', maxWidth: '100%', marginTop: '20px' }}>Our Committee</h1>
            <img src="/images/our-team.jpg" alt="Committee" style={{ height: 'auto', maxWidth: '100%' }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
