import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../css/Carousel.css'; // Import your custom CSS for the carousel container

const CarouselComponent = ({images}) => {
  return (
      <Carousel autoPlay infiniteLoop showThumbs={false}>
        {
          images.map((image,index)=> (
            <img src = { image } key = { index } alt={`CarouselImage ${index + 1}`} style={{height:'80vh'}}/>
          ))
        }

      </Carousel>
  );
};

export default CarouselComponent;
