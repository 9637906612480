import React from 'react';
import '../css/About.css'; // Import your custom CSS for styling
import UnstyledListComponent from '../components/UnstyledListComponent'

const About = () => {

  const achievementArray=[
    'Donating $2500.00 to build a wheelchair accessible toilet in Rupandehi, Nepal.',
    'Providing warm clothes to poor, orphan, and underprivileged children during winter, amounting to approximately $1000.00.',
    'Installing an electric water filter in a public hospital, an essential step towards improving healthcare, with a cost of $1250.00.',
    'Contributing $1000.00 to the central relief fund during the devastating earthquake in Nepal in 2015.',
    'Supporting a health post in a remote village by providing them with an analyzer machine, an investment of $2600.00 towards better healthcare.',
    'Donating 7 patient monitors to a children and women’s hospital in Butwal, empowering emergency services and costing $7500.00.',
    'Extending our help during Covid restrictions by supporting NRNA and providing financial aid and assistance to Covid-impacted Nepalese students.',
    'Providing financial support to numerous community organizations on a small scale.'
]

  return (
    <div>
      <div className='aboutBody'>
          <div className="row featurette">
              <div className="col-md-7">
                <h2 className="about-heading">About Us</h2>
                <p className="about-description">
                  Rupandehi Social Network Australia, popularly known as RSNA, was established on 4th October 2015. We are a non-profit volunteer organization committed to making a positive impact on our community.
                </p>
                <p><strong>Our Achievements:</strong></p>
                <UnstyledListComponent achievementArray={achievementArray}/>
              </div>
              
              <div class="col-md-5">
                <img src="/images/group-photo.jpg" className="img-fluid" alt="Committee"></img>
              </div>
          </div>
          <div className="container">
            <main>
              <div className="py-5 text-center">
                <h2>Contact Us</h2>
                <p>Get in touch with us</p>
              </div>

              <div className="row g-5">
                <div className="col-md-8 offset-md-2">
                  <form className="needs-validation" noValidate>
                    <div className="row g-3">
                      <div className="col-sm-6">
                        <label htmlFor="firstName" className="form-label">
                          First name
                        </label>
                        <input type="text" className="form-control" id="firstName" placeholder="" value="" required />
                        <div className="invalid-feedback">Valid first name is required.</div>
                      </div>

                      <div className="col-sm-6">
                        <label htmlFor="lastName" className="form-label">
                          Last name
                        </label>
                        <input type="text" className="form-control" id="lastName" placeholder="" value="" required />
                        <div className="invalid-feedback">Valid last name is required.</div>
                      </div>

                      <div className="col-12">
                        <label htmlFor="email" className="form-label">
                          Email <span className="text-body-secondary">(Optional)</span>
                        </label>
                        <input type="email" className="form-control" id="email" placeholder="you@example.com" />
                        <div className="invalid-feedback">Please enter a valid email address for shipping updates.</div>
                      </div>

                      <div className="col-12">
                        <label htmlFor="address" className="form-label">
                          Address
                        </label>
                        <input type="text" className="form-control" id="address" placeholder="Your address" required />
                        <div className="invalid-feedback">Please enter your address.</div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlTextarea1">Message</label>
                          <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                      </div>

                      <div className="col-12 text-center">
                        <button className="btn btn-success btn-sm" type="submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </main>
          </div>
      </div>
    </div>
  );
};

export default About;
